import styled from 'styled-components';
import SectionWrapper from '../SectionWrapper';
import Button from '../../global/Button';
import { queries } from "../../../breakpoints";

export const StyledCtaSectionHorizontal = styled(SectionWrapper)`
    text-align: left;
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media(${queries.max_break_lg}){
            flex-direction: column;
        }
        a {
            margin-left: 20px;
            @media(${queries.max_break_lg}){
                margin-left: 0px;
                margin-top: 20px;
            }
        }
    }
`;

export const Headline = styled.h2`
    font-weight: bold;
    padding-right: 20px;
    @media(${queries.max_break_lg}){
        padding-right: 0px;
        text-align: center;
    }
`; 

export const CtaHorizontal = styled(Button)`
    // Styles here are not working... Something in the Button setup is not allowing the styles to be extended
`;
import { CtaHorizontal, Headline, StyledCtaSectionHorizontal } from "./CtaSectionHorizontal.styles"
import React, { useContext } from 'react';

import { AnalyticsContext } from "../../../context";
import PropTypes from 'prop-types';
import { getPageSlug } from '../../../../helpers';

const CtaSectionHorizontal = (props) => {
  const { handleTealiumEvent } = useContext(AnalyticsContext)

  return(
  <StyledCtaSectionHorizontal backgroundColor={props.backgroundColor}>
    <Headline>{props.headline}</Headline>
    <CtaHorizontal 
      to={props.to}
      type={props.buttonType}
      onClick={() => handleTealiumEvent({ eventcategory: `${props.eventcategory}`, eventaction: `${props.eventaction}`, eventlabel: `${props.eventlabel}` })}
    >
      {props.ctaText}
    </CtaHorizontal>
    <CtaHorizontal 
      to={props.toSecond}
      type={props.buttonTypeSecond}
      onClick={() => handleTealiumEvent({ eventcategory: `${props.eventcategorySecond}`, eventaction: `${props.eventactionSecond}`, eventlabel: `${props.eventlabelSecond}` })}
    >
      {props.ctaSecondText}
    </CtaHorizontal>
  </StyledCtaSectionHorizontal>
  )
};

CtaSectionHorizontal.propTypes = {
  headline: PropTypes.string,
  backgroundColor: PropTypes.string,
  to: PropTypes.string,
  ctaText: PropTypes.string,
  ctaSecondText: PropTypes.string,
  buttonType: PropTypes.string,
  buttonSecondType: PropTypes.string,
  eventcategory: PropTypes.string,
  eventcategorySecond: PropTypes.string,
  eventaction: PropTypes.string,
  eventactionSecond: PropTypes.string,
  eventlabel: PropTypes.string,
  eventlabelSecond: PropTypes.string,
};

CtaSectionHorizontal.defaultProps = {
  headline: "",
  backgroundColor: null,
  to: "/get-started/",
  toSecond: "/get-started/",
  ctaText: "Get started",
  ctaSecondText: "Get started",
  buttonType: "primary",
  buttonSecondType: "secondary",
  eventcategory: `${getPageSlug()} interaction`,
  eventcategorySecond: `${getPageSlug()} interaction`,
  eventaction: "get started_cta",
  eventactionSecond: "get started_cta",
  eventlabel: "see what we can do for you",
  eventlabelSecond: "see what we can do for you",
};

export default CtaSectionHorizontal;

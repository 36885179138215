import CtaSectionHorizontal from "../components/sections/CtaSectionHorizontal"
import GeneralHeader from "../components/global/GeneralHeader"
import Layout from "../components/global/Layout"
import React from "react"
import ValuePropsSection from "../components/sections/ValuePropsSection"
import QuoteSection from "../components/sections/QuoteSection"
import styled from 'styled-components';

const SelfServiceInformation = ({ location }) => {

  const valueProps = [
    {
      headline:"Quick and easy",
      subheadline:"It only takes a few minutes to get your account up and running, so people can start ordering right away",
      imageSrc:"https://media-cdn.grubhub.com/image/upload/v1611866054/corporate/GH_Clock.svg",
    },
    {
      headline:"Endless options",
      subheadline:"No matter what people are craving, they can order anything and get it delivered anywhere",
      imageSrc:"https://media-cdn.grubhub.com/image/upload/v1611866054/corporate/GH_MealBurger.svg",
    },
    {
      headline:"Account management",
      subheadline:"All the tools you need to manage your account and view your order history at your fingertips",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1690191305/Corporate/digitalevents.png",
    },
  ]

  const partners = [
      {
          quote: "Create a credit",
          position: "Set how much, when, and who gets to use your credit.",
          imageSrc: "https://res.cloudinary.com/grubhub-assets/image/upload/v1651846385/corp-management-ui/illustration_marketing_createcredit.svg",
          alt: "Create a credit"
      },
      {
          quote: "Invite people",
          position: "When it’s time to eat, let people know. They can use their credit on Grubhub and have meals delivered anywhere.",
          imageSrc: "https://res.cloudinary.com/grubhub-assets/image/upload/v1651846383/corp-management-ui/illustration_marketing_invitepeople.svg",
          alt: "Invite people"
      },
      {
          quote: "Add a payment method",
          position: "Once an order is placed, your credit card will be charged only for the amount a person spends.",
          imageSrc: "https://media-cdn.grubhub.com/image/upload/v1611769716/corporate/CorpIllo_AddPaymentMethod.svg",
          alt: "Add a payment method"
      }
  ]

  const faqs = [
    {
      header: "Does Grubhub for corporate have a rewards program?",
      description: "A Grubhub Corporate Account offers free perks for corporate diners, like free items and dollars off!"
    },
    {
      header: "Why do companies offer free lunch?",
      description: "<span>Many companies offer free lunch to retain current employees and attract new talent as meal programs boost staff morale. Additionally, free <a href='/blog/3-ideas-to-improve-your-team-lunch-program/'>team lunch</a> can help increase productivity by making sure your employees are fed and focused.</span>"
    }
  ]

  return (
    <Layout
      location={location}
      title={"Corporate Food Delivery | Grubhub"}
      description={"Ready to get started with a Self-Service Corporate Account? It's free to sign up and you'll enjoy 0% corporate transaction fees for 12 months."}
      className="self-service-information-page"
      noindex={true}
      nofollow={true}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "self service information",
        pageName: "corporate - self service information",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <StyledNavbarOnlyLogo>
        <NavbarContainerOnlyLogo>
          <LogoHeader src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1663686777/HERMES/Logos/GH_Corporate_stacked-sRGB-OrangeGray.png" alt="Grubhub/Seamless Corporate Accounts" placeholder="blurred" width={196}/>
        </NavbarContainerOnlyLogo>
      </StyledNavbarOnlyLogo>
      <GeneralHeader
        headline="Keep your team well-fed and happy"
        subheadline="Delicious meals for any occasion delivered anywhere, anytime. It's free to sign up and you'll enjoy 0% Corporate transaction fees for 12 months."
        textColor="var(--color-secondary)"
        background="#FFF"
        ctaLink="https://www.grubhub.com/corporate/signup?utm_source=facebook&utm_medium=social_paid&utm_campaign=corpselfservice_signup_facebookQ32023"
        ctaText="Get started"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1690099257/Corporate/self-service-information-header.jpg"
        alt="Table filled with food containers such as shrimp tempura, edamame, rice and broccoli, sushi and noodles with two hands holding chopsticks reaching for the food."
        eventCategoryOptional="Facebook_getstarted_interaction"
        eventLabelOptional="get started facebook"
        eventActionOptional="getstarted_fb"

      />
      <ValuePropsSection
        headline="The tools you need for fast and reliable meals"
        valueProps={valueProps}
        layout="vertical"
        backgroundColor="#f6f6f6"
      />
      <HeadingPartners>
        <h2 className="quote__section_heading">Here's how it works</h2>
      </HeadingPartners>
      {partners.map((partner, i) => (
          <React.Fragment key={i}>
              <QuoteSection
                  quote={partner.quote}
                  author={partner.author}
                  position={partner.position}
                  imageSrc={partner.imageSrc}
                  imgQuote={false}
                  // If even numer, align right, else left
                  imageAlignment={ i % 2 === 0 ? "left" : "right"}
                  layout="horizontal"
                />
          </React.Fragment>
      ))}
      <CtaSectionHorizontal 
        ctaText="Get started" 
        to="https://www.grubhub.com/corporate/signup?utm_source=facebook&utm_medium=social_paid&utm_campaign=corpselfservice_signup_facebookQ32023" 
        headline="Because ordering food shouldn't feel like work" 
        backgroundColor="#F6F6F6" 
        ctaSecondText="Learn more" 
        toSecond="/get-started/" 
        buttonSecondType="secondary"
        eventcategory="Facebook_getstarted_interaction2"
        eventaction="getstarted_fb2"
        eventlabel="get started facebook2"
        eventcategorySecond="Facebook_learnmore_interaction"
        eventactionSecond="learnmore_fb"
        eventlabelSecond="learn more facebook"
      />
    </Layout>
  )
}

export default SelfServiceInformation

const HeadingPartners = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
    h2 {
      font-weight: bold;
    }
`;

const StyledNavbarOnlyLogo = styled.header`
    -webkit-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
    padding: 20px 0;
    z-index: 2000;
    position: relative;
`;

const NavbarContainerOnlyLogo = styled.div`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100%;
`;

const LogoHeader = styled.img`
    min-width: 200px;
    align-self: center;
`;